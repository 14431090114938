$primaryColor: #0061e8; // Color azul oscuro
$secondaryColor: #ff178b; // Color azul claro
$hoverColor: #b54bf5; // Un tono de azul diferente para hover

.banner {
  height: 85px;
  position: relative;
  z-index: 1;

  /* Tus estilos existentes para el fondo */
  background: linear-gradient(90deg, #1d0b28 0%, #405fcb 100%);
  background-image: url("../../public/blob-scene-haikei.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    /* Capa oscura */
    background: rgb(0 0 0 / 32%); /* Ajusta la opacidad según sea necesario */

    /* Asegura que la capa oscura cubra todo el banner */
    width: 100%;
    height: 100%;
  }

  h1,
  .gradient {
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h1 {
    font-size: 23px;
    font-weight: bold;
  }
  .btn {
    background-color: $secondaryColor;
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.9rem;
    border: none;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $hoverColor;
      color: white;
    }
  }
}
