.mobile-navbar-container {
  position: relative;

  #sidenav-wrapper {
    background-color: #ffffff;
    position: fixed;
    width: 100%;

    .sidenav {
      height: 100%;
      width: 0;
      position: fixed;
      background-color: #111;
      overflow-x: hidden;
      transition: 0.3s;

      @media screen and (max-height: 450px) {
        padding-top: 15px;

        a {
          font-size: 18px;
        }
      }

      .openedMenu {
        .text-decoration-none {
          color: #f1f1f1;
          font-size: 22px;
        }
      }

      ul {
        li {
          .item {
            border-radius: .35rem;

            &:hover {
              background-color: rgb(44, 44, 44);
            }
          }

          a {
            padding: 8px 8px 8px 32px;
            text-decoration: none;
            color: white;
            display: block;
            transition: 0.3s;
            white-space: nowrap;
          }
        }
      }
    }

    .closedMenu {
      .text-decoration-none {
        color: #000;
        font-size: 22px;
      }
    }
  }
}