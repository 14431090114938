.navbar {
  li {
    font-size: 22px;
  }

  li > a {
    color: #9b9b9b;
    text-decoration: none;
    display: inline-block;
    position: relative;
  }

  li > a::after {
    content: "";
    display: block;
    margin: auto;
    height: 3px;
    width: 0;
    top: 5px;
    background: transparent;
    transition: all 0.3s;
  }

  li > a:hover {
    color: #000;
  }

  li .active-option {
    color: black;
  }

  li .active-option::after {
    width: 100%;
    background: #0061e8;
  }
}
